export default {
  statsHeader: {
    myMoney: '내 머니',
    myPoint: '내 포인트',
    memberMoney: '회원 머니',
    memberPoint: '회원 포인트',
    eggs: '보유알',
    memberInquiry: '고객문의',
    depositApplication: '충전신청(대기)',
    withdrawApplication: '환전신청(대기)',
    affiliateApplication: '회원인증',
    totalMembers: '전체회원',
    currentMembers: '현재접속자',
    todayDepositAmount: '충전금액',
    todayWithdrawAmount: '환전금액',
    refundProfitAndLoss: '충환손익',
    manualMoney: '수동머니',
    manualPoint: '수동포인트',
    totalProfitLoss: '총손익',
    casinoBetAmount: '카지노베팅',
    casinoProfit: '카지노당첨',
    casinoRollingAmount: '카지노롤링',
    casinoProfitLoss: '카지노손익',
    slotBetAmount: '슬롯베팅',
    slotProfit: '슬롯당첨',
    slotRollingAmount: '슬롯롤링',
    slotProfitLoss: '슬롯손익',
    sportBetAmount: '스포츠베팅',
    sportProfit: '스포츠당첨',
    sportRollingAmount: '스포츠롤링',
    sportProfitLoss: '스포츠손익',
    miniGameBetAmount: '미니게임베팅',
    miniGameProfit: '미니게임당첨',
    miniGameRollingAmount: '미니게임롤링',
    miniGameProfitLoss: '미니게임손익',
  },
  menu: {
    undefined: '',
    Dashboard: '홈',

    // Downline Info Start
    'Downline Info': '파트너정보',
    Member: '회원관리',
    MemberTree: '트리뷰',
    Affiliate: '파트너관리',
    AffiliateSummary: '파트너리스트(계총)',
    // Downline Info End

    // Bet Management Start
    BetManagement: '베팅관리',
    LiveBetHistory : '라이브 베팅 기록',
    SlotBetHistory : '슬롯 베팅 기록',
    SportBetHistory : '스포츠 베팅 기록',
    MiniGameBetHistory: '미니게임 베팅 기록',
    // Bet Management End

    // Settlement Management Start
    SettlementManagement: '베팅관리',
    MonthlyStepByStep: '월별/단계별 정산',
    SettlementByCasinoSlotVendor: '카지노/슬롯 판매업체별 정산',
    CommissionHistoryList: '파트너 롤링피내역',
    StatisticsByMember: '회원별 통계',
    DepositWithdrawManagement: '입출금 관리',
    MemberPointRecord: '회원 포인트내역',
    // Settlement Management End

    gameRecord: '게임 기록',
    financeCentre: '머니정산',
    'Bet Record': '회원베팅기록',
    'Deposit Record': '회원 입금 기록',
    Statistics: '통계',
    'Game Stats': '게임 통계',
    'Affiliate Daily Report': '대리점 일일 보고서',
    'Affiliate Center': '대리점 센터',
    Deposit: '충전요청',
    'Bind Bank Cards': '환전계좌추가',
    'Bank Withdrawal': '은행 출금',
    Transfer: '대리입금',
    'Referral Centre': '추천 센터',
    'Referral Management': '추천 관리',
    'Referral Link': '회원 추천 링크',
    'Referral Material': '추천 자료',
    'Affiliate Domain': '전용 도메인',
    'Personal Center': '개인 센터',
    'Settlement Center': '수수료',
    'Finance Report': '실시간정산내역',
    'Settlement Report': '루징정산',
    'Channel Pack': '채널 패킹',
    contactUs: '문의하기',
    'Daily Detail': '일일 상세 내역',
    'Daily Summary': '일일 총괄',
    Withdraw: '잔액 출금',
    CommWithdraw: '커미션 잔액 출금',
    'Rebate Report': '리베이트 보고서',
    message: '사이트 메시지',
    'Bet Management': '베팅관리',
    'Bet History': '회원베팅기록',
    inquiry: '문의',
  },
  common: {
    title: '대리 시스템',
    logout: '로그아웃',
    changePassword: '비밀번호 변경',
    login: '로그인',
    verification_title: '보안 인증, 순서대로 클릭해주세요:',
    signup: '가입',
    username: '아이디 입력',
    loginnow: '로그인',
    password: '비밀번호',
    apply: '신청',
    verificationcode: '인증 코드',
    verify_success: '인증 성공',
    refresh: '새로고침',
    submit: '제출',
    register_affi: '파트너가입',
    back_login: '로그인으로 돌아가기',
    contact_us: '문의하기',
    affiliateaccountcanonlycontainnumchar:
      '대리 계정은 밑줄(_), 숫자 및 문자만 포함할 수 있습니다.',
    confirmpassword: '비밀번호 확인',
    affiliateaccount: '파트너아이디',
    forgetpass: '비밀번호를 잊으셨나요?',
    zhuanshukefufuwu: '전담 고객 서비스',
    askus: '문의하기',
    download: '다운로드',
    copy: '복사',
    copied: '복사 완료',
    email: '합영부 이메일',
    qq: '합영 QQ',
    skype: '합영부 Skype',
    paopao: '파오파오',
    zalo: '잘로',
    telegram: '텔레그램',
  },
  depositStatus: {
    SUCCESS: '입금 성공',
    SUPPLEMENT_SUCCESS: '입금 보충 성공',
    CLOSED: '닫힘',
    PENDING: '입금 대기 중',
  },
  withdrawStatus: {
    ALL: '전체',
    SUCCESS: '결제 성공',
    FAIL: '결제 실패',
    APPLY: '신청 중',
    STEP_1: '검토 중',
    STEP_2: '결제 대기 중',
    STEP_3: '결제 중',
    AUTOPAY: '자동 결제 중',
    PENDING: '중지됨',
    REJECT: '거부됨',
    SENDING: '발송 중',
    WAITING_CALLBACK: '자동 결제 대기 중',
    PAYING: '결제 중',
    WAITING_AUTO_PAY: '자동 결제 대기 중',
  },
  withdrawConfirmStatus: {
    0: '미확인',
    1: '입금 완료',
  },
  questions: {
    mothersName: '어머니의 성함은?',
    mothersBirthday: '어머니의 생일은?',
    dadsName: '아버지의 성함은?',
    dadsBirthday: '아버지의 생일은?',
    spouseBirthday: '배우자의 생일은?',
    cityBorn: '출생 도시는?',
    highSchool: '중학교 이름은?',
    elementarySchool: '초등학교 이름은?',
    firstPetName: '첫 번째 애완동물 이름은?',
    firstCar: '첫 번째 차의 브랜드는?',
  },
  gameType: {
    SLOT: '슬롯',
    LIVE: '실시간',
    FISH: '낚시',
    SPORT: '스포츠',
    ESPORT: '이스포츠',
    POKER: '포커',
    LOTTERY: '복권',
    MINIGAME: '미니게임',
  },
  result: {
    WIN: '승',
    LOSS: '패',
    DRAW: '무승부',
  },
  betStatus: {
    UNSETTLED: '미정산',
    SETTLED: '정산 완료',
    CANCEL: '취소됨',
  },
  transferType: {
    DEPOSIT: '한도 대리 입금',
    COMMISSION: '수수료 대리 입금',
  },
  creditFlowType: {
    Withdraw: '출금',
    Transfer: '이체',
    Dividen: '배당금',
    Deposit: '입금',
    Agent_Transfer: '대리 이체',
    Agent_Deposit: '대리 입금',
    COMMISSION_WALLET_WITHDRAWAL: '출금',
    COMMISSION_WALLET_AFFILIATE_DEPOSIT: '대리 입금',
    COMMISSION_WALLET_SETTLEMENT: '커미션 정산',
    DEPOSIT_WALLET_DEPOSIT: '입금',
    DEPOSIT_WALLET_AFFILIATE_DEPOSIT: '대리 입금',
    DEPOSIT_WALLET_AMOUNT_ADJUST: '금액 조정',
  },
  referralLink: {
    affiliateWebPlatformLink: 'PC 홍보 링크',
    affiliateH5PlatformLink: 'H5 홍보 링크 (권장)',
    affiliateWXShortLink: '단축 링크 (WeChat)',
    affiliateQQShortLink: '단축 링크 (QQ)',
    affiliateZALOShortLink: '단축 링크 (ZALO)',
    affiliateLongLink: '장문 링크',
    affiliateWXQRLink: 'QR 코드 (WeChat)',
    affiliateQQQRLink: 'QR 코드 (QQ)',
    affiliateZALOQRLink: 'QR 코드 (ZALO)',
    affiliateLongQRLink: '장문 QR 코드',
    affiliateDownloadQRtoLocal: '로컬에 다운로드',
    affiliateScanMe: 'QR 코드 스캔',
    copiedWXShortUrl: 'WeChat 단축 링크 복사 완료',
    copiedQQShortUrl: 'QQ 단축 링크 복사 완료',
    copiedLink: '복사 성공',
    downloadQRCode: 'QR 코드 다운로드',
    affiliateShortLink: '단축 링크',
  },
  commissionInfo: {
    commissionInfo: '커미션 정보',
    affiliateCommissionInfo: '대리 커미션 정보',
    commissionCalculator: '커미션 계산기',
    monthlyTotalEffectiveTurnover: '이번 달 총 수익 (원)',
    pleaseInsertMonthlyTotalEffectiveTurnover:
      '이번 달 총 수익을 입력해주세요 (원)',
    monthlySettlement: '이번 달 정산',
    pleaseInsertMonthlySettlement: '이번 달 정산을 입력해주세요',
    monthlyDividen: '이번 달 배당금',
    pleaseInsertMonthlyDividen: '이번 달 배당금을 입력해주세요',
    platformFee: '플랫폼 비용',
    monthlyTotalDeposit: '이번 달 회원 입금 (원)',
    pleaseInsertMonthlyTotalDeposit: '이번 달 회원 입금을 입력해주세요 (원)',
    monthlyTotalWithdrawal: '이번 달 회원 출금 (원)',
    pleaseInsertMonthlyTotalWithdrawal: '이번 달 회원 출금을 입력해주세요 (원)',
    totalCommissionAbleClaim: '이번 달 받을 수 있는 커미션 (원)',
    calculate: '계산',
    commissionCalculatorNote:
      '주의: 계산기는 평균 리베이트 및 배당금을 기준으로 하며, 결과는 참고용으로만 사용됩니다.',
    commissionRateInfo: '커미션 비율 정보',
    commissionRateDescription:
      '대리 커미션 비율 이에 따라 두 조건을 모두 만족할 경우 제휴 관계에 참여하기로 결정합니다.',
    activeMemberRequirement:
      '활성 회원 수: 해당 월의 예치금 >= 100 또는 유효한 베팅 >= 500;',
    level: '수준',
    companyMonthlyProfit: '회사 월간 이익',
    activeNumberRequirement: '신규/활성 개수 최소 요구 사항',
    commissionRate: '커미션 비율',
    commissionCalculateFormula: '커미션 계산',
    commissionFormula:
      '커미션 = 수정 후 순이익 * 수수료율 + 수수료 조정 + 수수료 보충; ',
    correctionNetProfitFormula: '수정 후 순이익 = 월 순이익 + 전월 잔액; ',
    monthlyNetProfitFormula:
      '월 순이익 = 월별 총 승 손실 - 보너스 - 리베이트 - 플랫폼 수수료 - 계정 조정 + 보충 승 손실 - 입출금 처리 수수료; ',
    bonusFormula: '보너스 = 이번 달 제휴사 다운라인이 받은 보너스 총액; ',
    rebateFormula: '리베이트 = 이번 달 제휴사 다운라인이 받은 리베이트 총액; ',
    platformFeeFormula:
      '플랫폼 수수료 = 각 플랫폼의 총 승패 * 플랫폼 수수료 요율. 단, 해당 월에 수익이 있을 경우에만 플랫폼에서 플랫폼 수수료를 부과합니다. ',
    adjustmentFormula:
      '계정 조정 = 회원 롤오버가 비정상적인 경우 사이트 관리자가 해당 회원에 대한 계정 조정을 실시합니다.',
    supplementWinLossFormula:
      '보충 승패 = 지난달 실시간 데이터 순이익 - 지난달 분배된 커미션 순이익; ',
    depositFeeFormula:
      '입금 수수료 = 다운라인 입금액 * 입금 수수료 요율 + 제휴사 입금액 * 입금 수수료 요율',
    withdrawalFeeFormula: '출금수수료 = 다운라인 출금금액 * 출금수수료율; ',
    commissionAdjustmentNote:
      '커미션 보충: 해당 월의 커미션이 출시된 후 제휴사가 참여한 관련 활동을 기반으로 커미션을 보충합니다. ',
    commissionNote:
      '참고: 커미션을 사기 위해 부정직한 방법을 사용하는 사람은 계정이 영구적으로 동결되고 파트너십이 종료된다는 점을 기억하십시오. 모든 커미션은 지불되지 않습니다. ',
  },
  sortType: {
    ASC: '오름차순',
    DESC: '내림차순',
  },
  rebateStatus: {
    PENDING: '보류 중',
    IN_PROGRESS: '진행 중',
    DISTRIBUTED: '분배하다',
    CANCEL: '취소',
  },
  fields: {
    account: '계정',
    accountInfo: '계정 정보',
    activeMember: '활성 회원',
    activePlayer: '활성화 플레이어',
    activeUsers: '활성 회원',
    add: '추가',
    addBankCard: '은행 카드 추가',
    addVirtualCard: '가상 카드 추가',
    adjust: '조정',
    adjustAmount: '조정 금액',
    adjustment: '오프라인 혜택',
    adjustReason: '조정 이유',
    adjustType: '정산 유형',
    affiliate: '대리',
    affiliateAccount: '파트너 아이디',
    affiliateCode: '추천코드',
    affiliateDeposit: '충전',
    affiliateInfo: '대리 정보',
    affiliateLevel: '파트너 레벨',
    affiliateStatus: '파트너 상태',
    affiliateWithdraw: '환전요청',
    affiliateWithdrawRecord: '출금 기록',
    amount: '변동금액',
    pointAmount: '금액',
    amountOfFirstDeposit: '첫 입금 금액',
    answerOne: '답변 1',
    answerTwo: '답변 2',
    answerThree: '답변 3',
    answerSecurityQuestion: '모든 보안 질문에 답변해주세요',
    appLink: '대리 백엔드 앱 다운로드 링크',
    balance: '잔액',
    bank: '은행',
    bankCard: '은행 카드',
    bankId: '은행 ID',
    bankName: '은행 이름',
    bankCode: '은행 코드',
    bet: '베팅 금액',
    betMembers: '베팅 회원 수',
    betRecord: '베팅 기록',
    betRecordDetails: '베팅 기록 상세',
    betTime: '베팅 시간',
    bind: '연결',
    bindAccountList: '연결 계정 목록',
    bindBankCardList: '연결 은행 카드 목록',
    bindCryptoList: '연결 가상 계정 목록',
    binded: '연결됨',
    bindEWalletList: '연결 전자지갑 목록',
    bindSecurityQn: '보안 질문 설정',
    bindWithdrawPw: '출금 비밀번호 설정',
    bonus: '보너스',
    bulk_read: '대량 읽기',
    bulk_delete: '대량 삭제',
    cancel: '취소',
    cardAccount: '은행 계좌 번호',
    cardAddress: '은행 카드 주소',
    cardNumber: '계좌번호',
    changeWithdrawPw: '출금 비밀번호 변경',
    clearingSum: '정산 총합',
    commission: '커미션',
    gamecommission: '플랫폼 수수료',
    commissionBalance: '커미션 잔액',
    commissionPercent: '배당 %',
    commissionRate: '커미션 비율',
    commissionReport: '커미션 상세',
    commissionTransfer: '커미션 대리 입금',
    commissionWallet: '커미션 지갑',
    companyProfit: '회사 이익',
    confirm: '확인',
    confirmNewPassword: '새 비밀번호 확인',
    confirmStatus: '확인 상태',
    confirmWithdraw: '출금 확인',
    copy: '복사',
    createAffiliate: '대리 추가',
    creditFlow: '금액변동내역',
    creditFlowAmount: '변동 금액(원)',
    creditFlowId: '번호',
    creditFlowType: '변동 유형',
    creditFlowDate: '시간',
    creditFlowBalance: '잔액 (원)',
    createTime: '시간',
    crypto: '암호화폐',
    currentPassword: '현재 비밀번호',
    deduct: '차감',
    deposit: '입금',
    depositAmount: '입금 금액',
    depositBettingAmount: '입금/베팅',
    depositCount: '입금 횟수',
    depositDate: '주문 제출 시간',
    depositRecord: '충전 기록',
    depositUsers: '충전 회원 수',
    depositWallet: '잔액 지갑',
    domainAffiliate: '전용 도메인',
    domainApp: '모바일 전용 도메인',
    domainWeb: 'PC 전용 도메인',
    downlineAffiliate: '하부파트너',
    downlineCommission: '하위 배당',
    downlineCommissionRate: '하위 배당 비율',
    downlineMember: '하위 회원',
    downlineProfit: '하위 수익',
    download: '다운로드',
    edit: '수정',
    editAffiliate: '대리 수정',
    editRealName: '실명 수정',
    email: '이메일',
    endDate: '종료 날짜',
    enquire: '문의',
    enterTheWithdrawalAmount: '출금 금액을 입력해주세요',
    estimatedAffiliateCommission: '예상 제휴사 커미션',
    estimatedMemberCommission: '예상 회원 커미션',
    ewallet: '전자 지갑',
    finalSum: '최종 커미션',
    finishDate: '주문 처리 시간',
    firstDepositAmount: '첫 입금 금액',
    firstDepositUsers: '첫 입금 회원 수',
    ftd: '첫 입금',
    gameName: '게임 이름',
    gameType: '게임 유형',
    lastLoginTime: '마지막 로그인 시간',
    lastMonth: '지난 달',
    lastMonthTotal: '지난 달 총계',
    lastWeek: '지난 주',
    link: '초대 링크',
    loginName: '회원아이디',
    loginPassword: '로그인 비밀번호',
    member: '회원',
    memberBetRecords: '회원 베팅 기록',
    memberCommission: '회원 배당',
    memberInfo: '회원 정보',
    memberProfit: '대리 이익',
    memberProfitDownlineProfitUnsettleCommission:
      '대리 이익/하위 이익/지난 달 잔액',
    memberType: '회원 유형',
    minActiveMemberCount: '최소 활성 회원 수',
    month: '월',
    monthlyAffiliateCommission: '이번 달 제휴사 커미컨',
    monthBeforeLastTotal: '지난 지난 달 총계',
    monthlyMemberCommission: '이번 달 회원 커미션',
    newMemberCount: '새 회원 수',
    activeMemberCount: '활성 회원 수',
    totalRegisterCount: '총 등록 수',
    myAccount: '내 계정',
    realName: '실명',
    name: '이름',
    netProfit: '순이익',
    newPassword: '새 비밀번호',
    newUsers: '신규 가입 회원 수',
    ngr: 'NGR',
    noData: '데이터 없음',
    actions: '작업',
    operate: '작업',
    operationalData: '운영 데이터',
    password: '비밀번호',
    paymentFee: '입출금 수수료',
    paymentMethod: '결제 방법',
    paymentName: '충전 채널',
    payout: '배당금',
    personal: '개인 정보',
    personalInfo: '개인 정보',
    privilegeSerialNumber: '코딩',
    privilegeRecord: '제안 목록',
    privilegeName: '제안 이름',
    platform: '플랫폼',
    platformFee: '플랫폼 수수료',
    profit: '총 이익',
    questionOne: '질문 1',
    questionTwo: '질문 2',
    questionThree: '질문 3',
    readed: '읽음',
    rebate: '리베이트',
    recordTime: '기록 시간',
    reenterPassword: '비밀번호 재입력',
    referralCode: '추천 코드',
    referralLink: '회원 추천 링크',
    regenerate: '다시 생성',
    registerTime: '등록 시간',
    reset: '재설정',
    result: '결과',
    revenueShare: '수익 공유',
    revenueShareRate: '수익 공유 비율',
    rollover: '롤링 배수',
    search: '검색',
    secondLevelAffiliateCommission: '본사 커비션',
    securityInfo: '보안 정보',
    securityQuestion: '보안 질문',
    select: '선택',
    selectACard: '카드 선택',
    selectBank: '은행 선택',
    selectBankCard: '은행 카드 선택',
    selectUsdtWallet: 'USDT 지갑 계정 선택',
    serialNumber: '주문 번호',
    paymentType: '유형',
    settleTime: '결산 시간',
    settleView: '보기',
    settlePay: '지급',
    settleEdit: '조정',
    site: '사이트',
    startDate: '시작 날짜',
    status: '상태',
    subtotal: '소계',
    systemAlert: '시스템 알림',
    affiliateSuccessSubmit:
      '존경하는 파트너님, 귀하의 자료 제출이 성공적으로 완료되었습니다. 저희 에이전트 전문가는 24시간 이내에 귀하에게 심사 결과를 알려 드릴 것입니다. 궁금한 점이 있으시면 저희 에이전트 전문가 또는 온라인 고객 서비스에 문의해 주십시오. 감사합니다.',
    systemAnnouncement: '시스템 공지',
    telephone: '전화번호',
    thirdLevelAffiliateCommission: '부본사 커미션',
    thisYear: '올해',
    thisThreeMonths: '최근 3개월',
    thisMonth: '이번 달',
    thisWeek: '이번 주',
    title: '제목',
    today: '오늘',
    totalBet: '총 베팅액',
    totalCompanyProfit: '총 회사 이익',
    totalCommission: '총 커미션',
    totalCommissionProfit: '커미션',
    totalDeposit: '총 입금액',
    totalDownlineAffiliate: '총 하위 에이전트',
    totalDownlineMember: '총 하위 회원',
    totalPayout: '총 배당금',
    totalTransfer: '총 대출금',
    totalWithdraw: '총 출금액',
    transactionId: '베팅 티켓 번호',
    transfer: '대리입금',
    transferAmount: '입금금액',
    transferDate: '대출 날짜',
    transferIn: '입금',
    transferOut: '출금',
    transferRecord: '대리입금 기록',
    transferType: '대출 유형',
    transferUsers: '대리충전 회원 수',
    unread: '읽지 않음',
    upperName: '상위 명칭',
    usdtWallet: '지갑 계정',
    usdtWalletAddress: '지갑 주소',
    unsettleCommission: '지난달 잔여 커미션',
    updateBy: '업데이터',
    viewDetails: '자세히 보기',
    vipLevel: 'VIP 등급',
    visitsNo: '방문 수',
    winLoss: '손익',
    withdraw: '출금',
    withdrawDate: '출금 날짜',
    withdrawAmount: '출금 금액',
    withdrawCount: '출금 횟수',
    withdrawPassword: '출금 비밀번호',
    withdrawPayoutBonus: '출금/배당금/보너스/조정',
    withdrawalAmount: '출금 금액',
    yesterday: '어제',
    memberTag: '회원 태그',
    memberTagDescription: '10자 이내의 이름을 입력하세요',
    tagSetting: '태그 설정',
    createTag: '태그 생성',
    editTag: '태그 편집',
    memberAmount: '회원 수',
    delete: '삭제',
    sequence: '순서',
    back: '뒤로',
    all: '전체',
    yes: '예',
    no: '아니요',
    more: '더 보기',
    remark: '회원 메모',
    bonusRecord: '보너스 기록',
    ftdTime: '첫 입금 시간',
    validBet: '유효베팅 금액',
    unAssigned: '미할당',
    batchEditTag: '일괄 태그 편집',
    personalReport: '개인 보고서',
    platformFeePercentage: '플랫폼 수수료율',
    rolloverAmount: '롤오버 금액',
    privilegeType: '특전 유형',
    personalCommission: '개인 커미션',
    payTime: '지급 시간',
    payStatus: '지급 상태',
    accountAdjustment: '계정 조정',
    commissionAdjustment: '커미션 조정',
    image: '이미지',
    imageTitle: '이미지 제목',
    imageType: '이미지 유형',
    uploadTime: '업로드 시간',
    imageSize: '이미지 크기',
    downloadTime: '다운로드 횟수',
    generateQR: 'QR 코드 생성',
    qrCode: 'QR 코드',
    textInfo: '텍스트 정보',
    qrCodeColor: 'QR 코드 색상',
    qrCodeBgColor: 'QR 코드 배경 색상',
    qrCodeLink: 'QR 코드 링크',
    qrCodePreview: 'QR 코드 미리보기',
    text: '텍스트',
    textColor: '텍스트 색상',
    textBgColor: '텍스트 배경 색상',
    textPreview: '텍스트 미리보기',
    preview: '미리보기',
    custom: '사용자 정의',
    packHistory: '패키지 기록',
    packType: '패키지 항목',
    selectSystem: '시스템 선택',
    appVersion: '현재 버전 번호',
    appName: '애플리케이션 이름',
    appIcon: '애플리케이션 아이콘',
    packNow: '지금 패키징',
    upload: '업로드',
    packDate: '패키징 날짜',
    osType: '시스템 유형',
    buildStatus: '빌드 상태',
    apkType: 'APK 종류',
    normal: '정상',
    newKey: '새 키',
    detail: '세부 사항',
    packSize: '패키지 크기',
    packDownloadUrl: '채널 패키지 링크',
    unchanged: '변경 없음',
    unbind: '연결 해제',
    depositWithdrawalProfit: '입출금 이익',
    registerCount: '신규 등록 사용자 수',
    ftdCount: '첫 입금자 수',
    ftdAmount: '첫 입금액',
    indBet: '베팅',
    indProfit: '베팅 배당 이익',
    last3Months: '지난 3개월',
    total: '합계',
    betAmount: '베팅 금액',
    payoutAmount: '배당금',
    totalMemberDepositAmount: '총 충전 금액',
    totalMemberDepositCount: '총 충전 회원 수',
    bonusAmount: '보너스 금액',
    rebateAmount: '리베이트 금액',
    indBonusAmount: '보너스 금액',
    indRebateAmount: '리베이트 금액',
    indAdjustAmount: '자금 조정 금액',
    grossProfit: '총 이익',
    totalBetMemberCount: '총 베팅 회원 수',
    totalDepositMemberCount: '총 충전 회원 수',
    orderBy: '정렬 기준',
    sortType: '정렬 방식',
    exclusiveDomain: '전용 도메인',
    pcWay: 'PC',
    mobileWay: '모바일',
    forgetPassword: '비밀번호 분실',
    authenticator: '인증기',
    withdrawRecord: '은행 출금',
    createMember: '회원 생성',
    shareRatio: '지분율',
    rebateWallet: '롤링지갑',
    affiliateTotalRebate: '총롤링포인트',
    myRebate: '내롤링포인트',
    totalWinLoss: '총손익',
    todayDeposit: '금일충전금액',
    todayWithdrawal: '금일총환전',
    depositWithdrawalDiff: '충환손익',
    recommenderCode: '추천인 코드',
    sendMessageType: '정보 유형',
    message: '정보',
    messageType: '정보 유형',
    messageTitle: '제목',
    messageContent: '콘텐츠',
    messageSendTime: '보내는 시간',
    addAffiliateMessage: '오프라인 플레이어 정보 추가',
    editAffiliateMessage: '하위라인 회원 정보 편집',
    messageSend: '보내다',
    receivedType: '전송 유형',
    receivedMembers: '플레이어 계정',
    example: '예를 들어',
    memberUsername: '계정',
    memberShareRatio: '플레이어 리베이트 비율',
    editShareRatio: '리베이트 수정',
    inquiry: '문의',
    totalRolling: '총 롤링',
    rolling: '구르는',
    numberOfbets: '베팅 횟수',
    redemptionRate: '상환율',
    profitLoss: '이익 손실',
    rebatePercentage: '리베이트(롤링피) 비율',
    type: '유형',
    beforeBalance: '변동 전 금액',
    applicationDate: '신청 날짜',
    processingDate: '처리 날짜',
    pendingRebate: '보류 중인 리베이트',
    recycle: '머니회수',
    nickName: '별명',
    afterBalance: '변동 후 잔액',
    redeemPoint: '포인트 교환',
    affiliateBalance: '파트너 잔액',
    memberBalance: '하부총잔액',
    affiliatePoint: '내포인트',
    memberCount: '회원수',
    affiliateWithdrawAmount: '총판입금금액',
    withdrawTransferDiff: '충환전손익',
    liveRolling: '하부총카지노롤링',
    slotRolling: '하부총슬롯롤링',
    sportRolling: '하부총스포츠롤링',
    miniGameRolling: '하부미니게임롤링',
    memberPoint: '회원 포인트',
    affiliateTransferAmount: '송금 금액',
    redeem: '상환하다',
  },
  google: {
    google_auth_menu: '구글 인증',
    download_install: '다운로드 및 설치',
    add_key: '키 추가',
    bind_verify: '바인딩 확인',
    popup_title: '인증기',
    popup_msg:
      '인증기는 구글의 동적 비밀번호 도구로, 30초마다 자동으로 갱신됩니다. 에이전트 백엔드에서 이체, 출금, 보안 설정 등의 민감한 작업을 수행할 때 이 6자리 인증 코드를 입력하여 신원을 확인하십시오.',
    popup_hint: '인증기는 에이전트 계정과 함께 사용해야 합니다.',
    i_know: '알겠습니다',
    download_way: '다운로드 방법:',
    download_through_way: '1. 다음 주소에서 다운로드:',
    ios_download: 'iOS 다운로드',
    android_download: 'Android 다운로드',
    ios_scan_download: 'IOS QR 코드 다운로드',
    android_scan_download: 'Android QR 코드 다운로드',
    if_cannot_download_you_can:
      '2. 다운로드할 수 없는 경우, Apple Store에서 "구글 인증기"를 검색하거나, Android 앱 스토어에서 검색하여 다운로드 및 설치하십시오.',
    keyin_pass: '비밀번호를 입력하고 다음 단계로 이동하십시오',
    keyin_your_password: '로그인 비밀번호를 입력하십시오',
    next_step: '다음 단계',
    tips_installed:
      '팁: 설치가 완료되었으면 "다음 단계"를 클릭하여 계속 진행하십시오.',
    qr_code: 'QR 코드',
    secret_key: '비밀 키',
    add_step: '추가 단계',
    add_step_desc:
      '구글 인증기를 열고 오른쪽 하단의 "+"를 클릭한 다음 "비밀 키 직접 입력"을 선택하고 임의의 계정과 위의 비밀 키를 입력하여 바인딩하십시오 (QR 코드를 스캔하면 자동으로 추가할 수 있습니다)',
    check_example: '예시 보기',
    back: '뒤로',
    small_tips: '작은 팁:',
    small_tips_info_1:
      '1. 핸드폰을 분실하거나 인증기를 제거한 후에도 비밀 키를 사용하여 인증기를 복구할 수 있으므로 안전하게 보관하십시오.',
    small_tips_info_2:
      '2. 귀하의 계정을 안전하게 유지하려면 바인딩 시 에이전트 계정 및 에이전트 백엔드 주소를 표시하지 마십시오.',
    keyin_6_digit_google: '6자리 구글 인증 코드를 입력하십시오',
    auth_code: '인증 코드',
    bind_now: '지금 바인딩',
    please_enter_password: '비밀번호를 입력하십시오',
    added_success: '구글 인증 코드가 성공적으로 추가되었습니다.',
    if_not_google_auth_then:
      '구글 인증기를 바인딩하지 않은 경우 입력할 필요가 없습니다',
    google_auth_code: '6자리 구글 인증 코드를 입력하십시오',
  },
  forgetPassword: {
    verifyAuth: '신원 확인',
    verifyQues: '보안 질문',
    resetPassword: '비밀번호 재설정',
    verify: '확인',
    answer: '답변',
    submit: '제출',
    reset: '재설정',
    messageAuth: '사용자 이름과 구글 인증 코드를 입력하십시오',
    messageQues: '다음 보안 질문에 답하십시오',
    messageReset: '새 비밀번호를 재설정하십시오',
    noSecurityQuestionSet:
      '이 계정에는 보안 질문이 설정되어 있지 않습니다. 고객 서비스에 문의하십시오.',
    resetSuccess: '비밀번호가 성공적으로 재설정되었습니다.',
  },
  message: {
    addSuccess: '추가 성공',
    adjustSuccess: '조정 성공',
    bindBankCard: '먼저 은행 카드를 바인딩하십시오',
    bindUsdtWallet: '먼저 가상 화폐 지갑을 바인딩하십시오',
    chineseCharacters: '중국어 문자를 입력하십시오',
    commissionPaySuccess: '커미션 지급 성공',
    confirmToAdjust:
      '조정을 진행하시겠습니까? 조정을 진행하면 변경할 수 없습니다',
    confirmToPay: '커미션을 지급하시겠습니까?',
    deleteSuccess: '삭제 성공',
    domainAppCopied: '모바일 전용 도메인이 클립보드에 복사되었습니다',
    domainWebCopied: 'PC 전용 도메인이 클립보드에 복사되었습니다',
    editSuccess: '편집 성공',
    inputRealName: '회원의 실제 이름을 입력하지 않았습니다',
    emailFormat: '올바른 이메일 형식을 입력하십시오',
    inputChineseCharacters: '중국어 문자를 입력하십시오',
    inputDigits: '숫자를 입력하십시오',
    inputPassword: '비밀번호를 입력하십시오',
    inputPasswordAgain: '비밀번호를 다시 입력하십시오',
    length6To12: '문자열은 6~12자여야 합니다',
    lengthShouldBe: '길이는 ',
    passwordLength: '비밀번호는 6자 이상, 12자 이하이어야 합니다',
    redirectBankDeposit:
      '입금을 계속하려면 지정된 은행으로 리디렉션됩니다. 입금이 성공하면 여기에서 확인할 수 있습니다.',
    reenterPassword: '비밀번호를 다시 입력하십시오',
    referralLinkCopied: '추천 링크가 클립보드에 복사되었습니다',
    remaining: '남은 횟수',
    requiredAffiliateLevel: '에이전트 레벨을 입력하십시오',
    required_signup_account: '에이전트 계정은 필수입니다',
    required_6_to_12: '6~12자리 숫자 또는 문자로 구성',
    requried_password: '로그인 비밀번호는 필수입니다',
    required_captcha: '캡차는 필수입니다',
    required_4_digits: '4자리 숫자로 구성',
    required_6_digits_code: '6자리 숫자로 구성',
    required_only_digits_and_alphabet: '숫자와 알파벳으로 구성되어야 합니다.',
    required_confirm_pwd: '비밀번호 확인은 필수입니다',
    required_same_with_password: '로그인 비밀번호와 일치하지 않습니다',
    requiredAmount: '금액을 입력하십시오',
    requiredAnswer: '답변을 입력하십시오',
    requiredCardAccount: '은행 계좌를 입력하십시오',
    requiredCardAddress: '은행 주소를 입력하십시오',
    requiredCardNumber: '계좌번호를 입력하십시오',
    requiredCommission: '커미션을 입력하십시오',
    requiredEmail: '이메일을 입력하십시오',
    requiredLoginName: '사용자 이름을 입력하십시오',
    requiredMemberType: '회원 유형을 선택하십시오',
    requiredOldPassword: '현재 비밀번호를 입력하십시오',
    requiredPassword: '비밀번호를 입력하십시오',
    requiredRealName: '이름을 입력하십시오',
    requiredRevenueShare: '수익을 입력하십시오',
    requiredRollover: '롤오버 배수를 입력하십시오',
    requiredTelephone: '전화번호를 입력하십시오',
    requiredTransferAmount: '대리입금 금액을 입력하십시오',
    requiredUsdtWallet: '가상 지갑 계정을 입력하십시오',
    requiredUserName: '사용자 이름을 입력하십시오',
    requiredWithdrawPassword: '출금 비밀번호를 입력하십시오',
    selectAQuestion: '질문을 선택하십시오',
    selectBankCard: '은행 카드를 선택하십시오',
    selectUsdtWallet: '가상 지갑 계정을 선택하십시오',
    setSecurityQn: '보안 질문을 먼저 설정하십시오',
    setSecAndPw: '보안 질문 및 출금 비밀번호를 먼저 설정하십시오',
    setWithdrawPassword: '출금 비밀번호를 먼저 설정하십시오',
    singleLimit: '단일 한도',
    success: '성공',
    times: '회',
    twoPasswordNotMatch: '비밀번호가 일치하지 않습니다',
    unbindConfirm: '연결을 해제하시겠습니까?',
    validateAdjustAmountRequired: '조정 금액은 필수입니다',
    validateAdjustReasonRequired: '조정 사유는 필수입니다',
    validateAdjustTypeRequired: '조정 유형은 필수입니다',
    validateBankCardNumber: '계좌번호는 숫자만 포함할 수 있습니다',
    validateCommission: '커미션은 0과 1 사이여야 합니다',
    validateNumberOnly: '숫자만 입력하십시오',
    validateNumberMoreThanOne: '1보다 큰 정수를 입력하십시오',
    validateRevenueShare: '수익은 0과 1 사이여야 합니다',
    validateUsdtWallet: '가상 지갑 계정은 숫자와 문자만 포함할 수 있습니다',
    withdrawalToday: '오늘 출금',
    inputUSDTAmount: 'USDT 금액을 입력하십시오',
    inputAmount: '입금 금액을 입력하십시오',
    selectAmount: '입금 금액을 선택하십시오',
    minDepositeAmount: '최소 입금액',
    maxDepositeAmount: '최대 입금액',
    currencyRates: '실시간 환율',
    understand: '이해함',
    DepositCompleted: '입금 완료',
    depositNotification1: '입금을 완료하려면 은행 페이지로 리디렉션됩니다.',
    depositNotification2: '성공하면 이 페이지에서 알림을 받게 됩니다.',
    validateTagDescriptionRequired: '회원 태그는 필수입니다',
    confirmDelete: '이 데이터를 삭제하시겠습니까? 이 작업은 되돌릴 수 없습니다',
    remarkMessage: '200자 이내로 회원 메모를 입력하십시오',
    validateTagDescriptionLength: '태그는 1자',
    packRequestInQueue: '채널 패킹 요청이 대기 중입니다',
    channelPackCancelSuccess: '채널 패킹이 성공적으로 취소되었습니다',
    channelPackUrlCopied: '채널 패킹 링크가 클립보드에 복사되었습니다',
    validateAppNameRequired: '앱 이름은 필수입니다',
    validateAppIconRequired: '앱 아이콘은 필수입니다',
    validateShareRatioFormat: '공유 비율은 0에서 100 사이여야 합니다.',
    depositShouldBetween: '입금액은 다음 사이여야 합니다 ',
    requiredPositiveInteger: '숫자를 입력해주세요',
    replySuccess: '성공적인 답변',
    confirmRecycle: '회원의 재활용 잔액 확인 ',
    recycleSuccess: '돈 회수 성공',
    yourCurrentPoint: '현재 포인트 :',
    confirmRedeem: '당신은 상환하고 싶나요 ?',
    redeemSuccess: '성공적인 상환',
  },
  posterType: {
    OVERALL: '종합 홍보 이미지',
    APP: '앱 홍보 이미지',
    SPONSOR: '후원 홍보 이미지',
    GIFT: '증정 홍보 이미지',
    COMPETITION: '경기 홍보 이미지',
    EVENT: '이벤트 홍보 이미지',
    CRYPTO: '가상 화폐 홍보 이미지',
    AFFILIATE: '대리점 혜택 이미지',
  },
  packStatus: {
    IN_QUEUE: '대기 중',
    IN_PROGRESS: '진행 중',
    SUCCESS: '성공',
    FAIL: '실패',
    CANCEL: '취소',
  },
  appType: {
    ALL_SITE: '전체 사이트',
    SPORT: '스포츠',
    ESPORT: '이스포츠',
  },
  member: {
    status: {
      normal: '정상',
      frozen: '동결',
    },
  },
  affiliate: {
    level: {
      JUNIOR_AFFILIATE: '플레이어',
      SUB_AFFILIATE: '매장',
      AFFILIATE: '부본사',
      SUPER_AFFILIATE: '본사',
      MASTER_AFFILIATE: '총판',
    },
    status: {
      APPLY: '신청',
      NORMAL: '정상',
      DISABLE: '비활성화',
    },
  },
  osType: {
    ANDROID: '안드로이드',
    IOS: 'IOS',
  },
  affiliateShareRatio: {
    AGENT_SHARE_BET_RATIO_FISH: '배팅비율-FISH',
    AGENT_SHARE_BET_RATIO_SLOT: '배팅비율-SLOT',
    AGENT_SHARE_BET_RATIO_ESPORT: '배팅비율-ESPORT',
    AGENT_SHARE_BET_RATIO_LIVE: '배팅비율-LIVE',
    AGENT_SHARE_BET_RATIO_SPORT: '배팅비율-SPORT',
    AGENT_SHARE_BET_RATIO_CASUAL: '배팅비율-CASUAL',
    AGENT_SHARE_BET_RATIO_POKER: '배팅비율-POKER',
    AGENT_SHARE_PROFIT_RATIO_FISH: '이익률-FISH',
    AGENT_SHARE_PROFIT_RATIO_SLOT: '이익률-SLOT',
    AGENT_SHARE_PROFIT_RATIO_ESPORT: '이익률-ESPORT',
    AGENT_SHARE_PROFIT_RATIO_LIVE: '이익률-LIVE',
    AGENT_SHARE_PROFIT_RATIO_SPORT: '이익률-SPORT',
    AGENT_SHARE_PROFIT_RATIO_CASUAL: '이익률-CASUAL',
    AGENT_SHARE_PROFIT_RATIO_POKER: '이익률-POKER',
  },
  sendMessageType: {
    NOTIFICATION: '통지',
    ACTIVITY: '활동',
    ANNOUNCEMENT: '발표',
    ALL_DOWNLINE_MEMBER: '모든 오프라인 플레이어',
    SELECTED_MEMBER: '플레이어 선택',
  },
  error: {
    400: '잘못된 요청',
    403: '접근 금지',
    405: '허용되지 않은 메서드',
    500: '시스템 오류',
    501: '중복된 요청 ID',
    502: '서버를 찾을 수 없음',
    504: '요청이 너무 자주 발생함',
    601: '토큰 유효성 검사 실패',
    602: '토큰 만료됨',
    603: '토큰을 찾을 수 없음',
    604: '계정이 이미 로그인됨',
    608: '사용자 작업이 너무 빈번함',
    707: '잔액 부족',
    709: '대리점 수수료 잔액 부족',
    800: '인증 코드 실패',
    801: '인증 코드 만료됨',
    901: '존재하지 않는 추천 코드입니다',
    902: '이 사용자는 대리가 아님',
    903: '이 계정은 이미 대리임',
    904: '이 계정은 회원입니다',
    905: '실제 이름이 아직 입력되지 않음',
    1000: '로그인 이름 또는 비밀번호가 잘못됨',
    1001: '로그인 실패, 계정이 비활성화됨',
    1100: '새 비밀번호는 이전 비밀번호와 동일할 수 없음',
    1101: '잘못된 이전 비밀번호',
    1102: '잘못된 지불 비밀번호',
    1201: '답변이 잘못됨',
    1308: '잔액 부족',
    1311: '24시간 내에 동일한 출금 금액을 제출하지 마십시오',
    13000: '도메인이 존재하지 않음',
    14000: '사용자 이름이 이미 사용 중입니다 : ',
    14001: '전화 번호가 이미 사용 중입니다 : ',
    14002: '이메일이 이미 사용 중입니다 : ',
    14003: '대리 코드가 존재하지 않음 : ',
    14004: '대리 코드가 이 사이트에 속하지 않음 : ',
    14005: '대리 수수료는 상위 대리 수수료보다 적어야 함 : ',
    14006: '대리 수입은 상위 대리 수입보다 적어야 함 : ',
    14007: '대리 신청이 아직 승인되지 않음',
    14008: '대리 수수료는 하위 대리 수수료보다 많아야 함 : ',
    14009: '대리 수입은 하위 대리 수입보다 많아야 함 : ',
    14100: '이 대리에는 이하 회원이 없음',
    14102: '회원 태그가 이미 존재함',
    14103: '회원 태그가 이미 한도에 도달함',
    15201: '잘못된 이전 비밀번호',
    15202: '새 비밀번호는 이전 비밀번호와 동일할 수 없음',
    14106: '비밀번호가 잘못됨',
    24000: '검증 실패',
    1009: 'Google 인증 코드가 잘못 입력됨',
    900: '사용자가 존재하지 않음',
    11011: '우량주 비율 미설정',
    11012: '게임 유형에 대해 우수한 공유 비율이 설정되지 않았습니다: ',
    11013: '계열사 지분 비율은 우월 비율을 초과할 수 없습니다.',
    14012: '낮은 레벨의 제휴만 생성 가능',
    14013: '제휴사에는 다운라인이 있으므로 더 낮은 수준으로 설정할 수 없습니다.',
    40006: '제휴사에 사용할 포인트가 부족합니다.',
  },
}
